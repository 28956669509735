// 
$(() => {
  $('#campaign_silver_discount_type').change(() => $('form').find('#campaign_silver_discount_value').each((i, el) => {
    if ($('#campaign_silver_discount_type').val() === 'percentage') {
      $(el).attr('placeholder', ' %');
    }
    if ($('#campaign_silver_discount_type').val() === 'amount') {
      $(el).attr('placeholder', ' kr');
    }
  }));

  $('#campaign_gold_discount_type').change(() => $('form').find('#campaign_gold_discount_value').each((i, el) => {
    if ($('#campaign_gold_discount_type').val() === 'percentage') {
      $(el).attr('placeholder', ' %');
    }
    if ($('#campaign_gold_discount_type').val() === 'amount') {
      $(el).attr('placeholder', ' kr');
    }
  }));

  $('#campaign_diamond_discount_type').change(() => $('form').find('#campaign_diamond_discount_value').each((i, el) => {
    if ($('#campaign_diamond_discount_type').val() === 'percentage') {
      $(el).attr('placeholder', ' %');
    }
    if ($('#campaign_diamond_discount_type').val() === 'amount') $(el).attr('placeholder', ' kr');
  }));

  const toggleCpaButton = ($el) => {
    if ($el.is(':checked')) {
      $('form').find('.cpa-button').removeClass('cpa-button-hidden');
    } else {
      $('form').find('.cpa-button').addClass('cpa-button-hidden');
    }
  };

  $('#cpa-button-checkbox').change((e) => { toggleCpaButton($(e.target)); });

  toggleCpaButton($('#cpa-button-checkbox'));

  const toggleBulkModeActive = ($el) => {
    if ($el.is(':checked')) {
      $('form').find('.campaign-type').addClass('bulk-mode-active');
      $('form').find('.bulk-table').removeClass('bulk-mode-active');
    }
  };

  $('#campaign_bulk_campaign_true').change((e) => toggleBulkModeActive($(e.target)));

  const toggleBulkModeDeactivate = ($el) => {
    if ($el.is(':checked')) {
      $('form').find('.campaign-type').removeClass('bulk-mode-active');
      $('form').find('.bulk-table').addClass('bulk-mode-active');
    }
  };

  $('#campaign_bulk_campaign_false').change((e) => { toggleBulkModeDeactivate($(e.target)); });

  const availableInput = document.querySelector('[data-target="available-input"]');
  const activeInput = document.querySelector('[data-target="active-input"]');
  if (availableInput && activeInput) {
    const toggleActiveInput = () => {
      if (availableInput.value === 'true') {
        activeInput.disabled = true;
        activeInput.value = true;
      } else {
        activeInput.disabled = false;
      }
    };

    toggleActiveInput();

    availableInput.addEventListener('change', toggleActiveInput);
  }
});
