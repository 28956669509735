// 
$(() => {
  $('#course_marketing_short_video, #course_marketing_long_video').each((i, el) => {
    const $label = $(el).siblings('label');
    const url = $(el).val();
    const iconOpen = url ? '<i class="fa fa-fw fa-external-link"></i>' : '';
    const iconCopy = '<i class="fa fa-fw fa-clipboard"></i>';
    return $label.html($label.html() + '<a href=' + url + ' target="_blank">' + iconOpen + '</a>' + iconCopy);
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('#course_marketing_teacher_id, #course_marketing_marketing_contact_id').on('change', e => $.ajax('/staff/' + $(e.target).val(), {
    type: 'GET',
    dataType: 'json',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    success(res) {
      if ($(e.target).attr('id').indexOf('teacher') > -1) {
        $('#teacher_role').val(res.role);
        $('#teacher_first_name').val(res.first_name);
        $('#teacher_last_name').val(res.last_name);
      } else if ($(e.target).attr('id').indexOf('marketing_contact') > -1) {
        $('#marketing_contact_role').val(res.role);
        $('#marketing_contact_first_name').val(res.first_name);
        $('#marketing_contact_last_name').val(res.last_name);
      }
    },
    error(error) {
      console.log(error);
    },
  }));

  // --------------------------- Start of intro/outro videos upload ---------------------------
  const $introS3UploaderContainer = $('#course-marketing-intro-s3-uploader-container');
  const $introFileInput = $introS3UploaderContainer.find('.s3-file-input');
  const $introDropArea = $introS3UploaderContainer.find('.file-drop-area');

  const $outroS3UploaderContainer = $('#course-marketing-outro-s3-uploader-container');
  const $outroFileInput = $outroS3UploaderContainer.find('.s3-file-input');
  const $outroDropArea = $outroS3UploaderContainer.find('.file-drop-area');

  $introFileInput.on('dragenter focus', () => $introDropArea.addClass('is-active'));

  $introFileInput.on('dragleave blur drop', () => $introDropArea.removeClass('is-active'));

  $introFileInput.on('dragover', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  $introFileInput.on('drop', (e) => {
    e.preventDefault();
    $introFileInput[0].files = e.originalEvent.dataTransfer.files;
    $introFileInput.trigger('change');
  });

  // uploadVideoToS3 is defined in master-lessons.coffee
  $introFileInput.on('change', () => window.uploadVideoToS3($introS3UploaderContainer, $introFileInput, $introDropArea));

  $outroFileInput.on('dragenter focus', () => $outroDropArea.addClass('is-active'));

  $outroFileInput.on('dragleave blur drop', () => $outroDropArea.removeClass('is-active'));

  $outroFileInput.on('dragover', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  $outroFileInput.on('drop', (e) => {
    e.preventDefault();
    $outroFileInput[0].files = e.originalEvent.dataTransfer.files;
    $outroFileInput.trigger('change');
  });

  $outroFileInput.on('change', () => window.uploadVideoToS3($outroS3UploaderContainer, $outroFileInput, $outroDropArea));
  // --------------------------- End of intro/outro videos upload ---------------------------
});

$(document).ready(() => {
  const copyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  };

  $(document).on('click', '.fa-clipboard, .copy-resource-link-to-clipboard', (e) => {
    const $icon = $(e.currentTarget);
    let text = $icon.parent().siblings('input').val(); // form group that has input
    if (!text) { text = $icon.parent().siblings('img').attr('src'); } // form group that has img tag
    if ($icon.attr('class') === 'copy-resource-link-to-clipboard') { text = $icon.parent().siblings('.actions-column').find('.resource-download-link').attr('download'); }
    copyToClipboard(text);
    $icon.parent().append('<span class="text-danger">Copied!</span>');
    setTimeout(() => $icon.parent().children().last().remove(), 2000);
  });

  $(document).on('click', '.copy-resource-to-marketing', (e) => {
    $(e.currentTarget).html('Sending...');
    $(e.currentTarget).prop('disabled', 'disabled');
    $.ajax($(e.currentTarget).data('url') + '.json?resource_id=' + $(e.currentTarget).data('id'), {
      type: 'PATCH',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success() {
        window.location.reload();
      },
      error(error) {
        console.log(error);
      },
    },
    );
  });
});
