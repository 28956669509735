// 
$(() => {
  $('#student_university_id').change((e) => $.get($(e.target).data('update-url'), $('#student_university_id').closest('form').serialize(), 'script'));

  $('#student_year').change((e) => {
    if ($(e.target).val() === '') {
      $(e.target).closest('form').find(':submit').prop('disabled', true);
    }
    $.get($(e.target).data('update-url'), $('#student_year').closest('form').serialize(), 'script');
  });

  $('#student_agreed_to_terms').click((e) => {
    if ($(e.target).is(':checked')) {
      $(e.target).closest('form').find(':submit').prop('disabled', false);
    } else {
      $(e.target).closest('form').find(':submit').prop('disabled', true);
    }
  });

  $('#student_super_access').change((e) => $(e.target).closest('form').submit());

  $('#students_csv_export_button').click(() => {
    $('#students_csv_export_message').html('');
    $.ajax(window.location.href, {
      type: 'GET',
      data: {
        csv_export: true,
      },
      success() {
        $('#students_csv_export_button').blur();
        $('#students_csv_export_message').html('CSV export started, please download file manually from S3-storage. If large number of students is exported, please wait 2-3 minutes.');
      },
      error() {
        $('#students_csv_export_button').blur();
        $('#students_csv_export_message').html('Error occurred!');
      },
    },
    );
  });

  if ($('#student_banned').is(':checked')) {
    $('div.student_ban_reason').show();
  } else {
    $('div.student_ban_reason').hide();
  }

  $('#student_banned').change((e) => {
    const selection = $(e.target).is(':checked');
    $('div.student_ban_reason').toggle(selection);
  });
});
