// 
// custom css expression for a case-insensitive contains()
$.expr[':'].Contains = (a, i, m) => (a.textContent || a.innerText || '').toUpperCase().indexOf(m[3].toUpperCase()) >= 0;

const getFormData = formEl => $(formEl).serializeArray().reduce((x, y) => {
  x[y.name] = y.value;
  return x;
}, {});

$(() => {
  $('[data-behavior~=toggle-show-topic]').on('click', (event) => {
    event.preventDefault();
    const lessonsListTopic = $(event.target).closest('.lessons-list__topic');
    lessonsListTopic.find('.lessons-list__themes').slideToggle();
    lessonsListTopic.find('.lessons-list__topic-icon span').toggle();
  });

  $('[data-behavior~=toggle-show-theme]').on('click', (event) => {
    event.preventDefault();
    $(event.currentTarget).find('i').toggleClass('fa-rotate-90');
    const lessonsListTheme = $(event.target).closest('.lessons-list__theme');
    lessonsListTheme.find('.lessons-list__lessons').slideToggle();
  });

  $('[data-behavior~=toggle-filter-courses-list]').change((e) => {
    const filter = $(e.target).val();
    if (filter) {
      // expand the list completely
      $('.lessons-list__themes').show();
      $('.lessons-list__lessons').show();
      // this finds all links in a list that contain the input,
      // and hide the ones not containing the input while showing the ones that do
      $('.lessons-list').find('a:not(:Contains(' + filter + '))').closest('.lessons-list__lesson').slideUp();
      $('.lessons-list').find('a:Contains(' + filter + ')').closest('.lessons-list__lesson').slideDown();
    } else {
      $('.lessons-list__lesson').show();
      $('.lessons-list__lessons').collapse('hide');
    }
    return false;
  }).keyup((e) => {
    // fire the above change event after every letter
    $(e.target).change();
  });

  $('.toggle-course-favourite').on('click', (e) => {
    $('.toggle-course-favourite').prop('disabled', 'disabled');
    const data = $(e.currentTarget).data();
    let url = '/courses/' + data.id + '/';
    url += data.favourite ? 'remove_favourite' : 'set_favourite';
    url += '?format=json';
    $.ajax(url, {
      method: 'GET',
      dataType: 'text',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
    }).done(() => window.location.reload()).fail(() => $('.toggle-course-favourite').removeProp('disabled'));
  });

  $('.toggle-resource-visible').on('change', (e) => {
    $('#' + $(e.target).data('id')).val($(e.target).prop('checked'));
    e.target.form.submit();
  });

  $('.toggle-social-proof-incentive').on('change', (e) => {
    $('#' + $(e.target).data('id')).val($(e.target).prop('checked'));
    e.target.form.submit();
  });

  $('.toggle-free-access-resource').on('change', (e) => {
    $('#' + $(e.target).data('id')).val($(e.target).prop('checked'));
    e.target.form.submit();
  });

  $('.toggle-mega-quiz').on('change', (e) => {
    $('#' + $(e.target).data('id')).val($(e.target).prop('checked'));
    e.target.form.submit();
  });

  $(document).ready(() => {
    if (window.location.hash) {
      const section = window.location.hash.replace('#', '');
      $('.nav-item').removeClass('active');
      $('.course-section-wrapper').hide();
      try {
        $('#course-' + section + '-wrapper').show();
        $('li[id=course-' + section + ']').addClass('active');
      } catch (error) { console.log(error); }
    }
  });

  $('.nav-item').on('click', (e) => {
    $('.nav-item').removeClass('active');
    $('.course-section-wrapper').hide();
    const targetWrapperSelector = '#' + $(e.currentTarget).attr('id') + '-wrapper';
    $(targetWrapperSelector).show();
    $(e.target).addClass('active');
  });

  $('#courseResourceModal').on('show.bs.modal', (e) => {
    $('#shared-entity-info-modal').modal('hide');
    $('#entityConfirmationModal').modal('hide');
    $('#addExistingResourcesModal').modal('hide');
    if (e.relatedTarget.id === 'new-course-resource-button') {
      $(e.currentTarget).load(e.relatedTarget.dataset.url);
    } else if (e.relatedTarget.id === 'edit-for-all-parents-link') {
      $(e.currentTarget).load($('#edit-for-all-parents-link').data('url'));
    } else {
      $(e.currentTarget).load($('#edit-for-current-parent-link').data('url'));
    }
  });

  $('#close-confirmation-modal').on('click', (e) => $(e.currentTarget).load(e.target.baseURI));

  $('#courseSocialProofModal').on('show.bs.modal', (e) => $(e.currentTarget).load(e.relatedTarget.dataset.url));

  $('#massAssignmentModal form').on('submit', (e) => {
    e.preventDefault();
    $('#massAssignmentModalError').text('');
    const $submitBtn = $(e.target).find('[type=submit]');
    $submitBtn.prop('disabled', 'disabled');
    const formData = getFormData(e.target);
    if ((formData.specialisation === '') || (formData.university === '') || (formData.course === '') || (formData.year === '') || (formData.term === '')) {
      $submitBtn.removeProp('disabled');
      $('#massAssignmentModalError').text('Fill all input fields!');
    } else {
      e.target.submit();
    }
  });

  $('#archived-courses-select').change(() => {
    window.location = '/courses/' + $('#archived-courses-select').val() + '/edit';
  });

  $('#include_all_quizzes').on('click', () => {
    if ($('#include_all_quizzes').prop('checked') === true) {
      $('.mega_quiz_quizzes').prop('checked', true);
    } else {
      $('.mega_quiz_quizzes').prop('checked', false);
    }
  });

  // Course resources tooltips titles
  $('#name-tooltip').attr('title', 'This is only shown in AdminView, and students will not see the name of the resource.');
  $('#title-tooltip').attr('title', 'The title of the resource is what students see in the application.');
  $('#category-tooltip').attr('title', 'Category is a value that can be set to help group resources, and is shown to students.');
  $('#visible-tooltip').attr('title', 'If this value is checked, the resource will be visible to students on the course overview '
    + 'page and in the course specific and general resource page. The unique resource page is still '
    + 'accessible to students regardless of this value being checked. This value is unique to this course, '
    + 'and does not affect other courses where this course resource might exist.');
  $('#free-access-tooltip').attr('title', 'If this value is checked, any student can access the resource regardless of their course subscription. '
    + 'This value is unique to this course, and does not affect other courses where this course resource might exist.');
  $('#actions-tooltip').attr('title', 'Edit allows you to edit all instances of this course resource at the same time, or duplicate the course resource '
    + 'and only edit it for this course. Removing it will only remove it from this course, and not other courses that this '
    + 'course resource is added to.');
});
