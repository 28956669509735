// 
$(() => {
  // ------------------------ Admins/staff 2FA login ------------------------
  $('#set-up-2fa').click(() => $.ajax('/create_two_factor_auth_secret_key', {
    type: 'GET',
    success(res) {
      $('#qr-code').html(res.qr_code);
      $('#secret-key').html(res.secret_key);
      $('#2fa-intro').attr('hidden', true);
      return $('#2fa-steps').attr('hidden', false);
    },
    error() {
      $('#set-2fa-message').attr('hidden', false);
      return $('#set-2fa-message').html('Server error occured!');
    },
  },
  ));

  $('#finish-2fa-setup').click(() => {
    if (!$('#setup-input-2fa-code').val() || ($('#setup-input-2fa-code').val().length !== 6)) {
      $('#2fa-code-message').attr('hidden', false);
      return $('#2fa-code-message').html('Code has to be 6-digits long!');
    } else if (!$('#setup-input-2fa-code').val().match(/^\d+$/)) {
      $('#2fa-code-message').attr('hidden', false);
      return $('#2fa-code-message').html('Code can contain digits only!');
    } else {
      return $.ajax('/finish_two_factor_auth_setup', {
        type: 'GET',
        data: { two_factor_auth_code: $('#setup-input-2fa-code').val() },
        success(res) {
          if (res.error_message) {
            $('#2fa-code-message').attr('hidden', false);
            return $('#2fa-code-message').html(res.error_message);
          } else {
            $('#2fa-code-message').attr('hidden', true);
            return window.location.reload();
          }
        },
        error() {
          $('#2fa-code-message').attr('hidden', false);
          return $('#2fa-code-message').html('Server error occured!');
        },
      },
      );
    }
  });

  $('#admin_staff_login_button').click(() => {
    const resources = window.location.pathname.split('/')[1];
    const resource = resources.slice(0, resources.length - 1);
    const data = {};
    // This data format is very important. In order to work properly, Warden needs params
    // in nested resource format, e.g. {admin: {login_prams}}
    data[resource] = {
      email: $('#' + resource + '_email').val(),
      password: $('#' + resource + '_password').val(),
      remember_me: $('#' + resource + '_remember_me').prop('checked') === true ? '1' : '0',
    };
    return $.ajax('/' + resources + '/sign_in', {
      type: 'POST',
      data,
      beforeSend(xhr) {
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success(res) {
        if (res.user_email) {
          $('#new_' + resource).remove();
          $('#admin_staff_login_message').before(
            '<div class="form-inputs">'
              + '<label class="form-control-label" for="login_2fa_code_input">Google Authenticator Code</label>'
              + '<input class="form-control" type="string" id="login_2fa_code_input" />'
              + '</div>'
              + '</br>',
          );
          $('#admin_staff_login_button').before(
            '<a class="btn btn-success btn-gradient p-3 px-5 text-white" id="submit_2fa_code_button">Submit</a>',
          );
          $('#admin_staff_login_button').remove();
          $('#admin_staff_login_message').html('');

          // Handling Google Auth
          $('#login_2fa_code_input').keypress((e) => {
            const key = e.which;
            if (key === 13) {
              return $('#submit_2fa_code_button').trigger('click');
            }
          });
          return $('#submit_2fa_code_button').click(() => $.ajax('/' + resources.split('_')[0] + '/two_factor_auth_sign_in', {
            type: 'GET',
            data: { email: res.user_email, two_factor_auth_code: $('#login_2fa_code_input').val() },
            success(res1) {
              if (res1.error_message) {
                return $('#admin_staff_login_message').html(res1.error_message);
              } else {
                return window.location.replace(res1.after_path);
              }
            },
            error() {
              return $('#admin_staff_login_message').html('Server error occured!');
            },
          },
          ));
        } else {
          return window.location.replace(res.after_path);
        }
      },
      error(er) {
        $('#admin_staff_login_message').html('');
        $('#' + resource + '_password').val('');
        if ((er.status === 401) && (er.responseText.includes('.invalid') || er.responseText.includes('.not_found_in_database'))) {
          return $('#admin_staff_login_message').html('Invalid account or password!');
        } else if (er.responseText.includes('logge inn eller registrere deg for')) {
          return $('#admin_staff_login_message').html(er.responseText);
        } else if (er.responseText.includes('Your account is deactivated.')) {
          return $('#admin_staff_login_message').html('Your account is deactivated.');
        } else {
          return $('#admin_staff_login_message').html('Server error occured!');
        }
      },
    },
    );
  });
  // --------------------- End of admins/staff 2FA login ---------------------
});
