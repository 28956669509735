// 
$(() => {
  //
  // Payment export
  //
  $('#payments_export_button').click((e) => {
    e.preventDefault();
    $('#payments_pdf_export_message').html('');
    $('#payments_export_confirmation_modal').modal('show');
  });

  const getUrlParam = (name) => {
    const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    return (results && results[1]) || null;
  };

  $('.payments_export_confirm_button').click((e) => {
    const type = $(e.currentTarget).data('id');
    $.ajax('/payments/export_multiple_invoices', {
      type: 'GET',
      data: {
        from: getUrlParam('from'),
        to: getUrlParam('to'),
        file_type: type,
      },
      success() {
        $('#payments_pdf_export_message').html(type.toUpperCase() + ' export started, please download the file manually from S3-storage. If large number of payments is exported, please wait couple of minutes.');
      },
      error() { $('#payments_pdf_export_message').html('Error occurred!'); },
    });
  });
});
