// 
// Activate discount code admin logic
$(() => {
  $('#code_code_type').change(() => $('form').find('input[id=code_amount]').each((i, el) => {
    if ($('#code_code_type').val() === 'percentage') {
      $(el).attr('placeholder', ' %');
    }
    if ($('#code_code_type').val() === 'amount') {
      $(el).attr('placeholder', ' kr');
    }
  }));

  // Activate repeat purchase admin logic
  $('#code_group_purchase').click(() => {
    if ($('#code_group_purchase').prop('checked')) {
      $('.group-purchase-visibility-settings').removeClass('group-purchase-active');
    } else $('.group-purchase-visibility-settings').addClass('group-purchase-active');
  });

  const purchaseTypeInput = document.querySelector('[data-target="purchase-type-input"]');
  const customerSupportInput = document.querySelector('[data-target="customer-support-input"]');
  if (purchaseTypeInput && customerSupportInput) {
    const toggleCustomerSupportInput = () => {
      if (purchaseTypeInput.value === 'true') {
        customerSupportInput.disabled = true;
        customerSupportInput.value = false;
      } else {
        customerSupportInput.disabled = false;
      }
    };

    toggleCustomerSupportInput();

    purchaseTypeInput.addEventListener('change', toggleCustomerSupportInput);
  }
});
