// 
$(() => $('select[name="recommended_course[university_id]"]').change(() => {
  const selected = $('select[name="recommended_course[university_id]"]').val();
  console.log('-- selected --');
  $.ajax('/get_specialisations_for_form', {
    method: 'GET',
    dataType: 'json',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    data: { universities: [selected] },
    success(data) {
      $('#recommended_course_specialisation_id').find('option').remove();
      for (const spec of Array.from(data)) {
        $('#recommended_course_specialisation_id').append(`<option value=${spec.id}>${spec.name}</option>`);
      }
      // alphabetically sorting options
      const options = $('#recommended_course_specialisation_id option');
      options.detach().sort((a, b) => {
        const at = $(a).text();
        const bt = $(b).text();
        if (at > bt) { return 1; } else if (at < bt) { return -1; } else { return 0; }
      });
      options.appendTo('#recommended_course_specialisation_id');
      // select first option by default
      $('#recommended_course_specialisation_id').val($('#recommended_course_specialisation_id option:first').val());
    },
  },
  );
}));
