// 
$(() => $('#shared-entity-info-modal').on('show.bs.modal', (e) => {
  $('#shared-entity-parents').empty();
  $('#addExistingResourcesModal').modal('hide');
  $('#courseResourceModal').modal('hide');
  $('.not-shared-among-entities').hide();
  $('.shared-among-entities').hide();
  const { sharedAmongEntities } = e.relatedTarget.dataset;
  const parents = e.relatedTarget.dataset.sharedAmong;
  const { currentParentId } = e.relatedTarget.dataset;
  const { currentParent } = e.relatedTarget.dataset;
  const entityResourceClass = e.relatedTarget.dataset.entityClass;
  const entityName = (entityResourceClass.split('::')[0]).toLowerCase() === 'course' ? 'course' : 'master_lesson';
  const { entityId } = e.relatedTarget.dataset;
  const { themeId } = e.relatedTarget.dataset;
  if (sharedAmongEntities > 1) {
    for (let parent of Array.from(JSON.parse(parents))) {
      if (currentParent === parent) {
        parent += ' (current)';
      }
      $('#shared-entity-parents').append('<li>' + parent + '</li>');
    }
    if (entityName === 'course') {
      $('#edit-for-all-parents-link').data('url', `/courses/resources/${entityId}/edit?course_id=${currentParentId}`);
    } else {
      $('#edit-for-all-parents-link').data('url', `/master_lessons/resources/${entityId}/edit?master_lesson_id=${currentParentId}&theme_id=${themeId}`);
    }
    $('.shared-among-entities').show();
  } else {
    if (entityName === 'course') {
      $('#edit-for-current-parent-link').data('url', `/courses/resources/${entityId}/edit?course_id=${currentParentId}`);
    } else {
      $('#edit-for-current-parent-link').data('url', `/master_lessons/resources/${entityId}/edit?master_lesson_id=${currentParentId}&theme_id=${themeId}`);
    }
    $('.not-shared-among-entities').show();
  }

  $('#entityConfirmationModal').on('show.bs.modal', () => {
    $('#shared-entity-info-modal').modal('hide');
    if (entityName === 'course') {
      $('#edit-for-current-parent-link').data('url', `/courses/resources/${entityId}/edit?course_id=${currentParentId}&clone=true`);
      $('#close-confirmation-modal').data('url', `/courses/${currentParentId}/edit?#resources`);
    } else {
      $('#edit-for-current-parent-link').data('url', `/master_lessons/resources/${entityId}/edit?master_lesson_id=${currentParentId}&clone=true&theme_id=${themeId}`);
      $('#close-confirmation-modal').data('url', `/master_lessons/${currentParentId}/edit?theme_id=${themeId}`);
    }
  });
}));
