// 
$(() => {
  $('#cancel_modal_button').click((ev) => {
    const { target } = ev.target.dataset;
    const infoText = target.includes('master_lessons') ? 'Master Lesson' : 'Quiz Question';
    const iboxContent = $('.ibox-content');
    const infoString = '<div class="alert alert-warning" id="alert_warning">'
                     + '<a href="#" class="close" aria-label="close">&times;</a>'
                     + '<strong>Warning!</strong> ' + infoText + ' was not updated.'
                     + '</div>';
    if (document.querySelectorAll('#alert_warning').length === 0) {
      $(infoString).insertBefore(iboxContent);
    }
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  });

  let currentNote = '';
  $('#sharebuster-note-button').click(() => {
    currentNote = $('#sharebuster_user_data_note')[0].value;
    if ($('#sharebuster_user_data_note')[0].disabled === true) {
      $('#sharebuster_user_data_note')[0].disabled = false;
    }

    if ($('#sharebuster_note_save_btn')[0].disabled === true) {
      $('#sharebuster_note_save_btn')[0].disabled = false;
    }

    if ($('#sharebuster_note_close_btn')[0].disabled === true) {
      $('#sharebuster_note_close_btn')[0].disabled = false;
    }
  });

  $('#sharebuster_note_close_btn').click(() => {
    if ($('#sharebuster_note_close_btn')[0].disabled === false) {
      $('#sharebuster_user_data_note')[0].value = currentNote;
      $('#sharebuster_note_close_btn')[0].disabled = true;
    }

    if ($('#sharebuster_user_data_note')[0].disabled === false) {
      $('#sharebuster_user_data_note')[0].disabled = true;
    }

    if ($('#sharebuster_note_save_btn')[0].disabled === false) {
      $('#sharebuster_note_save_btn')[0].disabled = true;
    }
  });

  document.addEventListener('DOMContentLoaded', () => {
    const calculationInputField = document.querySelector('.quiz-answer-calculation-input');
    const messageDiv = document.getElementById('warning-input-message');

    if (calculationInputField) {
      calculationInputField.addEventListener('input', () => {
        calculationInputField.value = calculationInputField.value.replace(/[^0-9,.-]/g, '');
        if (calculationInputField.value.length >= 10) {
          messageDiv.style.display = 'block';
        } else {
          messageDiv.style.display = 'none';
        }
      });
    }
  });
});
