// 
const getFormData = (formEl) => $(formEl).serializeArray().reduce((x, y) => {
  x[y.name] = y.value;
  return x;
}, {});

$(() => {
  //
  // Video upload
  //
  let setDevelopmentData; let setQuery; let uploadVideoToS3;
  const $s3UploaderContainer = $('#master-lesson-video-s3-uploader-container');
  const $fileInput = $s3UploaderContainer.find('.s3-file-input');
  const $dropArea = $s3UploaderContainer.find('.file-drop-area');

  $fileInput.on('dragenter focus', () => $dropArea.addClass('is-active'));
  $fileInput.on('dragleave blur drop', () => $dropArea.removeClass('is-active'));
  $fileInput.on('dragover', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });
  $fileInput.on('drop', (e) => {
    e.preventDefault();
    $fileInput[0].files = e.originalEvent.dataTransfer.files;
    $fileInput.trigger('change');
  });
  $fileInput.on('change', () => uploadVideoToS3($s3UploaderContainer, $fileInput, $dropArea));

  // This function is also used for upload course_marketing intro and outro videos
  uploadVideoToS3 = (uploaderContainer, fileInput, dropArea) => {
    let data; let query; let s3BucketUrl;
    const textContainer = fileInput.prev('.file-msg');
    const file = fileInput[0].files[0];
    const s3UploadFailed = (reason, detail) => {
      let text;
      if (detail == null) {
        detail = null;
      }
      text = 'Video upload failed ' + reason + '.';
      if (detail) {
        text += ' ERROR DETAIL: ' + detail;
      }
      textContainer.text(text);
      uploaderContainer.find('.upload, .upload-spinner').removeClass('active');
      dropArea.addClass('error');
    };

    if (file.type.indexOf('video/') !== 0) {
      textContainer.text('Invalid file type, please select a video');
    } else {
      textContainer.text('Uploading... Please wait.');
      s3BucketUrl = '';

      // In production enable uploading to s3 directly by the browser
      if (uploaderContainer.data('production')) {
        // get s3 presigned post url
        query = setQuery(uploaderContainer[0].id, file);
        $.ajax('/videos/presigned_s3_post_data?' + $.param(query), {
          method: 'GET',
          dataType: 'json',
          beforeSend(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          success(data1) {
            s3BucketUrl = data1.url;
            const formData = new FormData();
            // s3 rules require their form fields to come first in presigned_post
            Object.keys(data1.fields).forEach((key) => formData.append(key, data1.fields[key]));
            formData.append('file', file, data1.new_name);

            // send file directly to s3 using s3 presigned post url and data
            $.ajax(s3BucketUrl, {
              type: 'POST',
              contentType: false,
              processData: false,
              xhr: (data2) => {
                const xhr = new window.XMLHttpRequest(data2);
                xhr.upload.addEventListener('progress', ((event) => {
                  let percentComplete;
                  if (event.lengthComputable) {
                    percentComplete = event.loaded / event.total;
                    percentComplete = parseInt(percentComplete * 100, 10);
                    uploaderContainer.find('.upload-percentage').text(percentComplete + '%');
                    uploaderContainer.find('.upload .bar').css('width', percentComplete + '%');
                  }
                }), false);
                return xhr;
              },
              beforeSend() {
                uploaderContainer.find('.upload, .upload-spinner').addClass('active');
              },
              data: formData,
              success(res) {
                // AWS returns XML formated data which in js is traversed as HTML
                let s3VideoLink;
                s3VideoLink = res.getElementsByTagName('Key')[0].innerHTML;
                s3VideoLink = s3BucketUrl + '/' + s3VideoLink;
                textContainer.text(file.name + ' was successfully uploaded');
                uploaderContainer.find('.upload, .upload-spinner').removeClass('active');
                dropArea.addClass('success');
                uploaderContainer.find('.video-name').text(file.name);
                let videoLinkElement;
                if (s3VideoLink.includes('/course-intro')) {
                  videoLinkElement = 'intro_video_s3_link';
                } else if (s3VideoLink.includes('/course-outro')) {
                  videoLinkElement = 'outro_video_s3_link';
                } else {
                  videoLinkElement = 'video_s3_link';
                }
                document.getElementById(videoLinkElement).value = s3VideoLink;
              },
              error(res) {
                s3UploadFailed('at uploading to presigned post s3 url', res.responseText);
              },
            });
          },
          error() {
            s3UploadFailed('at getting presigned s3 post data');
          },
        });
      } else {
      // Dev environment
        data = setDevelopmentData(uploaderContainer[0].id, file);
        $.ajax('/videos/upload_s3_video', {
          type: 'POST',
          contentType: false,
          processData: false,
          xhr(data3) {
            const xhr = new window.XMLHttpRequest(data3);
            xhr.upload.addEventListener('progress', ((event) => {
              let percentComplete;
              if (event.lengthComputable) {
                percentComplete = event.loaded / event.total;
                percentComplete = parseInt(percentComplete * 100, 10);
                uploaderContainer.find('.upload-percentage').text(percentComplete + '%');
                uploaderContainer.find('.upload .bar').css('width', percentComplete + '%');
              }
            }), false);
            return xhr;
          },
          beforeSend(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            uploaderContainer.find('.upload, .upload-spinner').addClass('active');
          },
          data,
          success(res) {
            textContainer.text(file.name + ' was successfully uploaded');
            uploaderContainer.find('.upload, .upload-spinner').removeClass('active');
            dropArea.addClass('success');
            uploaderContainer.find('.video-name').text(file.name);
            let videoLinkElement;
            if (res.video_type === 'intro') {
              videoLinkElement = 'intro_video_s3_link';
            } else if (res.video_type === 'outro') {
              videoLinkElement = 'outro_video_s3_link';
            } else {
              videoLinkElement = 'video_s3_link';
            }
            document.getElementById(videoLinkElement).value = res.s3_link;
          },
          error() {
            textContainer.text('Video upload failed');
            uploaderContainer.find('.upload, .upload-spinner').removeClass('active');
            dropArea.addClass('error');
          },
        });
      }
    }
  };
  // We want "uploadVideoToS3" function to be available to other js files as well
  window.uploadVideoToS3 = uploadVideoToS3;

  //
  // Video chapters validation
  //
  function validateChapters(chaptersText) {
    const chaptersRegex = /^(\d{2}):(\d{2})\.(\d{3})\s(.+)$/;
    const errors = [];

    // Strip HTML tags and split by lines
    const lines = chaptersText
      .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== ''); // Remove empty lines

    if (lines.length === 0) return errors; // No chapters to validate

    let previousTime = null;

    lines.forEach((line, index) => {
      const match = line.match(chaptersRegex);

      if (!match) {
        errors.push(`Line ${index + 1} is not in the correct format.`);
        return;
      }

      const minutes = parseInt(match[1], 10);
      const seconds = parseInt(match[2], 10);
      const milliseconds = parseFloat(match[3]) / 1000;
      const currentTime = minutes * 60 + seconds + milliseconds;

      // Rule 1: First chapter must start at 00:00
      if (index === 0 && currentTime !== 0) {
        errors.push('The first chapter must start at 00:00.');
      }

      // Rule 2: Chapters must be in ascending order
      if (previousTime !== null && currentTime <= previousTime) {
        errors.push(`Line ${index + 1} must have a timestamp greater than the previous chapter.`);
      }

      // Rule 3: Each chapter must be at least 10 seconds long
      if (previousTime !== null && currentTime - previousTime < 10) {
        errors.push(`Line ${index} must be at least 10 seconds long before the next chapter.`);
      }

      previousTime = currentTime;
    });

    return errors;
  }
  // --------------------- End of video chapters validation ------------------------

  // Prevent form submission for the Master Lesson if the category field is not selected or chapters format is not valid.
  // We want to avoid redirecting with `redirect_to` when updating the master lesson fails,
  // as this would lead to the loss of unsaved master lesson content
  $('#master_lesson_form').on('submit', (e) => {
    const blankCategoryAlert = '<div class="alert alert-danger alert-dismissable"> <button button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> Category kan ikke være blank </div>';
    const invalidChaptersAlert = '<div class="alert alert-danger alert-dismissable"> <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> Chapters have validation errors. Please check the format. </div>';

    const formData = getFormData(e.target);
    const selectedCategory = formData['master_lesson[category]'];
    const chaptersInput = formData['master_lesson[video_attributes][chapters]'];
    const submitBtn = $(e.target).find('[id=submit_master_lesson]');
    let hasErrors = false;

    // Check if category is blank
    if (selectedCategory === '') {
      hasErrors = true;
      if ($('.flash-messages')) {
        $('.flash-messages').html(blankCategoryAlert);
      }
    }

    // Validate chapters
    const chapterErrors = validateChapters(chaptersInput);
    if (chapterErrors.length > 0) {
      hasErrors = true;
      if ($('.flash-messages')) {
        $('.flash-messages').html(invalidChaptersAlert);
      }
      const errorSpan = document.querySelector('.video-chapters-error');
      if (errorSpan) {
        errorSpan.innerHTML = chapterErrors.length > 0 ? chapterErrors.join('<br>') : '';
      }
    }

    // If there are errors, prevent form submission
    if (hasErrors) {
      e.preventDefault();
      setTimeout(() => submitBtn[0].removeAttribute('disabled'), 200);
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }
  });

  // Helpers
  setDevelopmentData = (uploaderContainerId, file) => {
    const data = new FormData();
    data.append('s3_file_input', file, file.name.replace(/\s+/g, '_'));
    if (uploaderContainerId.includes('master-lesson-video')) {
      data.append('master_lesson', true);
    } else if (uploaderContainerId.includes('course-marketing-intro')) {
      data.append('course_marketing', true);
      data.append('video_type', 'intro');
    } else if (uploaderContainerId.includes('course-marketing-outro')) {
      data.append('course_marketing', true);
      data.append('video_type', 'outro');
    }
    return data;
  };

  setQuery = (uploaderContainerId, file) => {
    let query;
    if (uploaderContainerId.includes('master-lesson-video')) {
      query = {
        filename: file.name,
        master_lesson_id: $('input[name="master_lesson_id"]').val(),
      };
    } else if (uploaderContainerId.includes('course-marketing-intro')) {
      query = {
        filename: file.name,
        course_marketing_id: $('input[name="course_marketing_id"]').val(),
        video_type: 'intro',
      };
    } else if (uploaderContainerId.includes('course-marketing-outro')) {
      query = {
        filename: file.name,
        course_marketing_id: $('input[name="course_marketing_id"]').val(),
        video_type: 'outro',
      };
    }
    return query;
  };

  // Convert to master lesson video to .mp4 format and download
  $('.upload-video-download-link').click(() => {
    const uploadedVideoLink = $('.upload-video-download-link').attr('data-video-link');
    const downloadLink = uploadedVideoLink.replace('output', 'input').replace('-master-playlist', '').replace('.m3u8', '.mp4');
    const link = document.createElement('a');
    link.href = downloadLink;
    link.download = downloadLink;
    link.click();
  });
  // --------------------- End of video upload section ------------------------

  //
  // Master lesson edit
  //
  $('#masterLessonResourceModal').on('show.bs.modal', (e) => {
    $('#shared-entity-info-modal').modal('hide');
    $('#entityConfirmationModal').modal('hide');
    $('#addExistingResourcesModal').modal('hide');
    if (e.relatedTarget.id === 'ml-resource-details-link') {
      $(e.currentTarget).load(e.relatedTarget.dataset.url);
    }
    if (e.relatedTarget.id === 'new-master-lesson-resource-button') {
      $(e.currentTarget).load(e.relatedTarget.dataset.url);
    } else if (e.relatedTarget.id === 'edit-for-all-parents-link') {
      $(e.currentTarget).load($('#edit-for-all-parents-link').data('url'));
    } else {
      $(e.currentTarget).load($('#edit-for-current-parent-link').data('url'));
    }
  });

  // Master lesson overview page tooltips
  $('#not-active-courses-tooltip').attr('data-original-title', 'If a master lesson exists in for example 3 courses, but all those 3 courses are set to inactive, then it is hidden from this list.');
  $('#archived-in-all-courses-tooltip').attr('data-original-title', 'If a master lesson has been added to 3 different courses, and in all those 3 courses the master lesson is archived, then it is hidden from this list.');

  // Master-lesson edit page manipulations
  const adjustForm = () => {
    if ($('#master_lesson_form').length) {
      const selected = $('#master_lesson_category').val();
      if (selected === 'article' || selected === 'quiz') {
        $('#assignment_container').hide();
        $('#master-lesson-video-s3-uploader-container').hide();
        $('#video-upload-info').hide();
        $('#video_chapters_container').hide();
        // we need to reset video_s3_link to avoid sending and creating the video as master lesson nested attribute
        document.getElementById('video_s3_link').value = '';
      } else {
        $('#assignment_container').show();
        $('#master-lesson-video-s3-uploader-container').show();
        $('#video-upload-info').show();
        $('#video_chapters_container').show();
      }
      if (selected === 'quiz') {
        $('#description_container').hide();
        $('.lesson-resources-section').hide();
        $('.mlesson-update-button-new-quiz').show();
        $('.mlesson-update-button').hide();
      } else {
        $('#description_container').show();
        $('.lesson-resources-section').show();
        $('.mlesson-update-button-new-quiz').hide();
        $('.mlesson-update-button').show();
      }
    }
  };

  $('#master_lesson_category').change(() => adjustForm());

  adjustForm();
  // --------------------- End of master lesson edit section ------------------------

  //
  // Video subtitles edit
  //
  let currentSubtitleIndex = 0;
  const subtitles = document.querySelectorAll('.subtitle-cue');
  const messageArea = document.getElementById('message-area');
  const messageText = document.querySelector('.message-text');
  const slider = document.getElementById('subtitle-slider');
  const videoId = $('#subtitle-container').data('video-id');
  let textChanged = false;

  // initial load
  const updateReviewedTooltips = () => $('.subtitle-cue').each((index, element) => {
    const reviewed = element.getAttribute('reviewed');
    const tooltip = $(element).find('.reviewed-tooltip');
    if (reviewed === 'true') {
      tooltip.show();
    } else {
      tooltip.hide();
    }
  });

  const checkAllReviewedStatus = () => {
    let allReviewed = true;
    const tooltip = $('.all-reviewed-tooltip');

    $('.subtitle-cue').each((index, element) => {
      const reviewed = element.getAttribute('reviewed');
      if (reviewed === 'false') {
        allReviewed = false;
        return false;
      }
    });

    if (allReviewed) {
      $('#update-all-btn').attr('disabled', true);
      tooltip.show();
    } else {
      $('#update-all-btn').attr('disabled', false);
      tooltip.hide();
    }
  };

  // Initial check when page loads
  updateReviewedTooltips();
  checkAllReviewedStatus();

  // ---------- messages ------------
  const showSuccessMessage = (response) => {
    messageText.innerText += response.message;
    messageArea.classList.add('success');
    messageArea.classList.remove('error');
  };

  const showErrorMessage = (response) => {
    messageText.innerText += response.message;
    messageArea.classList.add('error');
    messageArea.classList.remove('success');
  };

  const clearMessageArea = () => {
    messageArea.classList.remove('success', 'error');
    messageText.innerText = '';
  };

  const removeMessage = () => setTimeout(() => clearMessageArea(), 5000);

  // ---------- update methods ------------
  const updateSlider = (index) => {
    slider.value = index;
  };

  const updateReviewedAttribute = () => $('.subtitle-cue').each((index, element) => {
    $(element).attr('reviewed', true);
  });

  // ---------- server requests ------------
  const updateCue = (id) => $.ajax({
    url: '/videos/' + videoId + '/subtitle_cues/' + id,
    type: 'PATCH',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    data: {
      subtitle_cue: {
        reviewed: true,
      },
    },
    success() {
      const cueElement = $('#subtitle-' + id);
      cueElement.attr('reviewed', true);
      const tooltip = cueElement.find('.reviewed-tooltip');
      tooltip.show();
      checkAllReviewedStatus();
    },
  });

  const updateAllCues = () => {
    clearMessageArea();
    $.ajax({
      url: '/videos/' + videoId + '/subtitle_cues/update_all',
      type: 'PATCH',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: {},
      success(response) {
        showSuccessMessage(response);
        updateReviewedAttribute();
        updateReviewedTooltips();
        checkAllReviewedStatus();
      },
      error(responseText) {
        showErrorMessage(responseText.responseJSON);
      },
    });
    removeMessage();
  };

  const submitForm = (form) => {
    const parentDiv = form.closest('.subtitle-cue');
    const cueId = parentDiv.id.match(/\d+/)[0];
    const reviewed = parentDiv.getAttribute('reviewed') === 'true';
    const initialText = form.querySelector('textarea').innerHTML;
    const currentText = form.querySelector('textarea').value;
    const formData = new FormData(form);
    if (initialText !== currentText) {
      textChanged = true;
    }
    if (textChanged) {
      $.ajax(form.action, {
        method: 'POST',
        beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: formData,
        processData: false,
        contentType: false,
      }).done((response) => {
        clearMessageArea();
        if (response.success) {
          const cueElement = $('#subtitle-' + response.id);
          cueElement.attr('reviewed', true);
          const tooltip = cueElement.find('.reviewed-tooltip');
          tooltip.show();
          form.querySelector('textarea').innerHTML = currentText;
          textChanged = false;
          checkAllReviewedStatus();
        }
      }).fail((response) => showErrorMessage(response.responseJSON));
    } else if (!reviewed) {
      updateCue(cueId);
    }
    removeMessage();
  };

  // ---------- events ------------
  const moveToNextCue = () => {
    if (currentSubtitleIndex < subtitles.length - 1) {
      subtitles[currentSubtitleIndex].classList.remove('active');
      currentSubtitleIndex++;
      subtitles[currentSubtitleIndex].classList.add('active');
      updateSlider(currentSubtitleIndex);
    }
  };

  $('.previous-btn').on('click', (e) => {
    e.preventDefault();
    if (currentSubtitleIndex > 0) {
      subtitles[currentSubtitleIndex].classList.remove('active');
      currentSubtitleIndex--;
      subtitles[currentSubtitleIndex].classList.add('active');
      updateSlider(currentSubtitleIndex);
    }
  });

  $('.next-btn').on('click', (e) => {
    e.preventDefault();
    const submitBtn = e.target;
    const currentForm = submitBtn.closest('form');
    submitForm(currentForm);
    moveToNextCue();
  });

  $('#update-all-btn').on('click', () => updateAllCues());
  $('#close-message').on('click', () => clearMessageArea());
  $('#subtitle-slider').on('input', () => {
    subtitles[currentSubtitleIndex].classList.remove('active');
    currentSubtitleIndex = parseInt(slider.value, 10);
    subtitles[currentSubtitleIndex].classList.add('active');
  });
});
