// 
const $ = require('jquery');

window.$ = $;
require('bootstrap');
require('cocoon-js');

require('./other/admins.js');
require('./other/campaigns.js');
require('./other/codes.js');
require('./other/courses.js');
require('./other/course/marketing.js');
require('./other/helpers/shared_entity_modal.js');
require('./other/master_lessons.js');
require('./other/payments.js');
require('./other/recommended_courses.js');
require('./other/static_pages.js');
require('./other/students.js');
require('./other/tinyEditor/index.js');

// Since we don't use Rails-ujs any more, we need to write our own logic to keep some data attributes working
document.addEventListener('DOMContentLoaded', () => {
  // Support usage of "method" and "confirm" parameters
  document.body.addEventListener('click', (event) => {
    // Find the closest link (link_to helper) or button (button_to) with "data-method" attribute
    const triggerElement = event.target.closest('a[data-method], button[data-method]');
    if (triggerElement) {
      event.preventDefault();

      // Get confirmation message (if any) and show confirmation dialog
      const confirmMessage = triggerElement.getAttribute('data-confirm');
      if (confirmMessage && !window.confirm(confirmMessage)) {
        return; // Stop if confirmation is canceled
      }

      // Get the HTTP method from `data-method` (default to "GET" if not specified)
      const method = triggerElement.getAttribute('data-method') || 'GET';

      // Get the target URL from the "href" or form's "action"
      const url = triggerElement.tagName === 'A' ? triggerElement.href : triggerElement.closest('form')?.action;
      if (!url) return;

      if (method === 'GET') {
        window.location.href = url;
      } else {
        // For other methods than GET, create a hidden form and submit
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;

        // Add the CSRF token input
        const csrfToken = document.querySelector("meta[name='csrf-token']")?.content;
        if (csrfToken) {
          const csrfInput = document.createElement('input');
          csrfInput.type = 'hidden';
          csrfInput.name = 'authenticity_token';
          csrfInput.value = csrfToken;
          form.appendChild(csrfInput);
        }

        // Add a dynamic "_method" input
        const methodInput = document.createElement('input');
        methodInput.type = 'hidden';
        methodInput.name = '_method';
        methodInput.value = method.toLowerCase();
        form.appendChild(methodInput);

        // Append and submit the form
        document.body.appendChild(form);
        form.submit();
      }
    }
  });

  // Support usage of "disable-with" data attribute ("disable_with" parameter)
  // First case: Standalone clicks (outside forms)
  document.body.addEventListener('click', (event) => {
    // Look for either a button or an input (e.g. of type "button") with "data-disable-with" attribute
    const button = event.target.closest('button[data-disable-with], input[data-disable-with]');
    if (button && !button.closest('form')) {
      const disableWith = button.getAttribute('data-disable-with');
      if (disableWith !== 'false') {
        button.disabled = true;
        if (disableWith) {
          if (button.tagName === 'INPUT') {
            button.value = disableWith;
          } else {
            button.textContent = disableWith;
          }
        }
      }
    }
  });

  // Second case: Form submissions
  document.body.addEventListener('submit', (event) => {
    const form = event.target;
    // Find all buttons or inputs with "data-disable-with" in the form
    const buttons = form.querySelectorAll('button[data-disable-with], input[data-disable-with]');
    buttons.forEach((button) => {
      const disableWith = button.getAttribute('data-disable-with');
      if (disableWith !== 'false') {
        button.disabled = true;
        if (disableWith) {
          if (button.tagName === 'INPUT') {
            button.value = disableWith;
          } else {
            button.textContent = disableWith;
          }
        }
      }
    });
  });
});
