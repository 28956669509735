// 
$(() => {
  let currentTarget;
  let currentEditor;
  let newEquationAdded = false;
  // This disables all math-fields that are for show only.
  $('.possible_hidden_math_field math-field').each((i, element) => {
    element.disabled = true;
  });

  $(document).on('input', 'math-field.keyboard_math_eq', (e) => {
    const keyboardMFValue = e.target.value;
    if (currentTarget !== undefined) {
      currentTarget.value = keyboardMFValue;
      currentTarget.innerHTML = keyboardMFValue;
    }
    const latexEquation = $('.latex_equation')[0];
    if (latexEquation !== undefined) {
      latexEquation.value = keyboardMFValue;
    }
  });

  $(document).on('input', 'textarea.latex_equation', (e) => {
    const latexValue = e.target.value;
    const keyboardEquation = $('.keyboard_math_eq')[0];
    if (currentTarget !== undefined) {
      currentTarget.value = latexValue;
      currentTarget.innerHTML = latexValue;
    }
    if (keyboardEquation !== undefined) {
      keyboardEquation.value = latexValue;
    }
  });

  /* eslint-disable prefer-promise-reject-errors */
  const imagesUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
    if (blobInfo.blob().type === 'image/svg+xml') {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', '/uploader/image');
    xhr.setRequestHeader('X-CSRF-TOKEN', $("meta[name='csrf-token']").attr('content'));

    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject('HTTP Error: ' + xhr.status);
        return;
      }

      const json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location !== 'string') {
        reject('Invalid JSON: ' + xhr.responseText);
        return;
      }

      resolve(json.location);
    };

    xhr.onerror = () => {
      reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  });

  function initializeMathLive(event) {
    const iFrame = event.target.contentWindow.document;
    const head = iFrame.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//unpkg.com/mathlive@0.94.0';
    head.appendChild(script);
  }

  function createMathFieldElement(value) {
    const mfe = new MathfieldElement();

    // Math element attributes
    mfe.setAttribute('class', 'math_eq');
    mfe.setAttribute('id', 'formula');
    mfe.setAttribute('virtual-keyboard-mode', 'manual');
    mfe.setAttribute('keypress-sound', 'null');
    mfe.setAttribute('smart-superscript', 'false');
    mfe.innerHTML = value;

    // Math element styles
    mfe.style.maxWidth = 'max-content';
    mfe.style.display = 'inline-block';
    mfe.style.backgroundColor = '#ffffff';
    mfe.style.color = '#000000';

    return mfe;
  }

  function enableDeleteButton() {
    const closestTiny = currentEditor.iframeElement.closest('.tox-tinymce');
    const deleteBtn = closestTiny.querySelector('[title="Delete Math Equation"]');
    if (deleteBtn) {
      deleteBtn.className = 'tox-tbtn';
      deleteBtn.ariaDisabled = false;
    }
  }

  function disableDeleteButton() {
    const closestTiny = currentEditor.iframeElement.closest('.tox-tinymce');
    const deleteBtn = closestTiny.querySelector('[title="Delete Math Equation"]');
    if (deleteBtn) {
      deleteBtn.className = 'tox-tbtn tox-tbtn--disabled';
      deleteBtn.ariaDisabled = true;
    }
  }

  function setEquationStyle() {
    if (currentTarget) {
      currentTarget.style.outline = '2px solid #a0a0a0';
      currentTarget.style.borderRadius = '4px';
      enableDeleteButton();
    }
  }

  function resetEquationStyle() {
    if (currentTarget) {
      currentTarget.style.outline = null;
      currentTarget.style.borderRadius = null;
      // currentTarget = null;
      disableDeleteButton();
    }
  }

  function createEquationsDiv(targetValue) {
    setTimeout(() => {
      const equationsDiv = document.createElement('div');
      equationsDiv.className = 'MLK__equations';

      // create and fill math-field div
      const mathFieldDiv = document.createElement('div');
      mathFieldDiv.className = 'equation_container';

      const equationArea = new MathfieldElement();
      // Math element attributes
      equationArea.setAttribute('class', 'keyboard_math_eq');
      equationArea.setAttribute('id', 'formula');
      equationArea.setAttribute('virtual-keyboard-mode', 'manual');
      equationArea.setAttribute('keypress-sound', 'null');
      equationArea.setAttribute('smart-superscript', 'false');
      equationArea.addEventListener('focusout', (ev) => {
        ev.stopPropagation();
      });
      equationArea.innerHTML = targetValue;

      mathFieldDiv.appendChild(equationArea);

      // create and fill latex equation div
      const latexEquationDiv = document.createElement('div');
      latexEquationDiv.className = 'latex_container';
      const latexEquationArea = document.createElement('textarea');
      latexEquationArea.contentEditable = true;
      latexEquationArea.className = 'latex_equation';
      latexEquationArea.id = 'latex_equation';
      latexEquationArea.value = targetValue;
      latexEquationArea.addEventListener('focusout', (ev) => {
        ev.stopPropagation();
      });
      latexEquationDiv.appendChild(latexEquationArea);

      equationsDiv.append(mathFieldDiv, latexEquationDiv);

      $(equationsDiv).insertBefore('.MLK__backdrop');
    }, '500');
  }

  function createMathFieldContainer(mathElement) {
    // Define non-editable span container around a math-field element
    const container = document.createElement('span');
    container.classList.add('mce-math-container');
    container.contentEditable = false;

    // Define editable span wrapper around a math-field element
    const spanContainer = document.createElement('span');
    spanContainer.classList.add('mce-math-wrapper');
    spanContainer.contentEditable = true;
    spanContainer.appendChild(mathElement);

    container.appendChild(spanContainer);

    return container;
  }

  function insertMathFieldElement(range, mathElement) {
    const mathFieldContainer = createMathFieldContainer(mathElement);

    range.insertNode(mathFieldContainer);
  }

  function addMathLiveButtonsToToolbar(editor) {
    editor.ui.registry.addButton('mathLiveEquationButton', {
      text: 'Σ',
      tooltip: 'Add Math Equation',
      onAction () {
        mathVirtualKeyboard.hide();
        const mfe = createMathFieldElement('');

        const range = editor.selection.getRng();

        insertMathFieldElement(range, mfe);

        if (currentTarget) {
          resetEquationStyle();
        }

        currentEditor = editor;
        currentTarget = mfe;

        // this command define how quickly (in ms) a set of keys must be typed to be considered a shortcut
        // shorcuts sometimes cause an error, so we disable them in this way
        mfe.inlineShortcutTimeout = 1;

        newEquationAdded = true;
        setEquationStyle();

        // When the add equation button is pressed, automatically opens virtual keyboard
        mfe.executeCommand('toggleVirtualKeyboard');

        createEquationsDiv('');
      },
    });

    editor.ui.registry.addButton('destroyEquationButton', {
      icon: 'cancel',
      tooltip: 'Delete Math Equation',
      enabled: false,
      onAction () {
        if (currentTarget) {
          const closestMathContainer = currentTarget.closest('.mce-math-container');
          if (closestMathContainer) {
            closestMathContainer.remove();
            mathVirtualKeyboard.hide();
            disableDeleteButton();
          }
        }
      },
    });
  }

  function handleWarningMessage(leftLabel, rightLabel, textLength) {
    const messageLeft = document.getElementById('warning-label-message-left');
    const messageRight = document.getElementById('warning-label-message-right');
    if (leftLabel) {
      if (textLength >= 10) {
        messageLeft.style.display = 'block';
      } else {
        messageLeft.style.display = 'none';
      }
    } else if (rightLabel) {
      if (textLength >= 10) {
        messageRight.style.display = 'block';
      } else {
        messageRight.style.display = 'none';
      }
    }
  }

  function cleanUpContent(content) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Reverse through the nodes and remove empty trailing paragraphs from the end
    const children = Array.from(tempDiv.childNodes).reverse();
    for (const node of children) {
      if (node.childNodes.length === 0 && node.textContent === '\n') {
        node.remove();
      } else if (
        node.childNodes.length === 1
        && node.firstChild.nodeType === 3
        && node.firstChild.textContent.trim() === ''
      ) {
        node.remove();
      } else { break; }
    }

    return tempDiv.innerHTML.trim();
  }

  function containsLatex(text) {
    const latexRegex = /\$\$(.*?)\$\$/g;
    return latexRegex.test(text);
  }

  function replaceLatexWithMathFields(text) {
    const latexRegex = /\$\$(.*?)\$\$/g;
    const lines = text.split(/\n/);
    let finalContent = '';

    lines.forEach(line => {
      let newContent = '';
      let lastIndex = 0;
      let match = latexRegex.exec(line);

      // Loop through all LaTeX matches in each line
      while (match !== null) {
        // Add non-LaTeX text before the current match
        newContent += line.substring(lastIndex, match.index);

        // Extract the LaTeX content
        const latex = match[1].trim();

        const mathFieldElement = createMathFieldElement(latex);
        const mfContainer = createMathFieldContainer(mathFieldElement);

        // Add the MathLive field HTML to the content
        newContent += mfContainer.outerHTML;

        // Update lastIndex to continue processing the rest of the line
        lastIndex = match.index + match[0].length;

        match = latexRegex.exec(line);
      }

      // Add any remaining text after the last match
      newContent += line.substring(lastIndex);

      // Wrap the processed content of the line inside a <p> tag
      if (newContent.trim()) { // Only add <p> if the line has content
        finalContent += `<p>${newContent}</p>`;
      }
    });

    return finalContent;
  }

  function customMethods(editor) {
    addMathLiveButtonsToToolbar(editor);

    editor.on('PreInit', (ev) => {
      initializeMathLive(ev);
    });

    editor.on('focusout', (ev) => {
      if (ev.target.nodeName === 'MATH-FIELD') {
        const mathFieldValue = ev.target.getValue();
        ev.target.innerHTML = mathFieldValue;
      }
    });

    editor.on('focusin', (ev) => {
      const focusedTarget = ev.target;
      if (focusedTarget.nodeName === 'MATH-FIELD' && !newEquationAdded) {
        currentEditor = editor;
        currentTarget = focusedTarget;
        resetEquationStyle();
      }
      newEquationAdded = false;
    });

    editor.on('click', (ev) => {
      if (ev.target.nodeName === 'MATH-FIELD') {
        resetEquationStyle();
        const targetValue = ev.target.getValue();
        currentEditor = editor;
        currentTarget = ev.target;
        setEquationStyle();
        createEquationsDiv(targetValue);
      } else {
        mathVirtualKeyboard.hide();
        resetEquationStyle();
      }
    });

    editor.on('input', (ev) => {
      const inputTarget = ev.currentTarget;
      if (ev.target.nodeName === 'MATH-FIELD') {
        const eqValue = ev.target.getValue();
        ev.target.innerHTML = eqValue;
        const keyboardEquation = $('.keyboard_math_eq')[0];
        const latexEquation = $('.latex_equation')[0];
        if (keyboardEquation !== undefined) {
          keyboardEquation.value = eqValue;
        }
        if (latexEquation !== undefined) {
          latexEquation.value = eqValue;
        }
      }

      const leftLabel = ev.currentTarget.dataset.id === 'quiz_question_label_left';
      const rightLabel = ev.currentTarget.dataset.id === 'quiz_question_label_right';
      if (leftLabel || rightLabel) {
        const content = inputTarget.textContent;
        handleWarningMessage(leftLabel, rightLabel, content.length);
      }
    });

    editor.on('TableModified', (ev) => {
      if (ev && ev.table) {
        if (ev.table.parentElement && ev.table.parentElement.className === 'table-responsive') return;
        const wrapper = document.createElement('div');
        wrapper.className = 'table-responsive';
        wrapper.style.overflowX = 'auto';
        wrapper.style.width = '100%';
        wrapper.style.maxWidth = '100%';
        $(ev.table).wrap(wrapper);
      }
    });

    editor.on('InsertCustomChar', (ev) => {
      const currentBody = ev.target.getBody();
      const leftLabel = currentBody.dataset.id === 'quiz_question_label_left';
      const rightLabel = currentBody.dataset.id === 'quiz_question_label_right';
      if (leftLabel || rightLabel) {
        const content = currentBody.textContent;
        handleWarningMessage(leftLabel, rightLabel, content.length);
      }
    });

    // Handle pasted LaTeX content
    editor.on('paste', (event) => {
      const pastedData = (event.clipboardData || window.clipboardData).getData('text');
      if (containsLatex(pastedData)) {
        event.preventDefault();
        // Process the pasted content to replace LaTeX with MathLive fields
        const processedContent = replaceLatexWithMathFields(pastedData);

        // Insert the processed content into the TinyMCE editor
        editor.insertContent(processedContent);
      }
    });

    editor.on('submit', () => {
      const content = editor.getContent();

      const cleanedContent = cleanUpContent(content);
      editor.setContent(cleanedContent);
    });

    editor.addCommand('convertToMathField', () => {
      const selectedText = editor.selection.getContent({ format: 'text' });
      const mfe = createMathFieldElement(selectedText);

      editor.selection.setContent(mfe.outerHTML);
    });
  }

  window.tinymce.remove();

  function initTinyMCE() {
    window.tinymce.init({
      selector: '.tinymce',
      height: 300,
      menubar: false,
      toolbar: [
        'undo redo | bold italic underline | blocks | numlist bullist | forecolor | charmap | fullscreen |'
        + 'image link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow |'
        + 'mathLiveEquationButton destroyEquationButton',
      ],
      plugins: [
        'lists', 'link', 'image', 'charmap', 'preview', 'fullscreen', 'table', 'wordcount',
      ],

      block_formats: 'Body=p; Heading 1=h1; Heading 2=h2; Heading 3=h3',
      color_map: [
        '292929', 'Ink',
        'FFFFFC', 'Notebook',
        '2E69FF', 'Ballpoint',
        '008A66', 'Emeralite',
        'FFD553', 'Ticonderoga',
        'FF0000', 'Pilot',
      ],
      text_patterns: [
        { start: '$$', end: '$$', cmd: 'convertToMathField' },
      ],
      draggable_modal: true,
      extended_valid_elements: 'math-field[.*]',
      custom_elements: '~math-field',

      setup: customMethods,

      // images
      images_upload_handler: imagesUploadHandler,
      images_reuse_filename: true,
      elementpath: false,
      relative_urls: false,
      remove_script_host: false,
      image_caption: true,

      // dark mode
      // skin: 'oxide-dark',
      // content_css: 'dark',

      // remove branding logo
      branding: false,

      content_style: '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: white;}'
      + 'figure.image {display: inline-block; border: 1px solid gray; margin: 0 2px 0 1px; background: #f5f2f0;}'
      + 'figure.align-left {float: left;} figure.align-right {float: right;}'
      + 'figure.image img {margin: 8px 8px 0 8px;}'
      + 'figure.image figcaption {margin: 6px 8px 6px 8px;text-align: center;}'
      + `
          @font-face {
            font-family: 'STKBureu-SansBook';
            src: font-url('assets/woff2/STKBureau-SansBook.woff2') format('woff2');
            font-weight: normal;
            font-style: normal;
          }

          @font-face {
            font-family: 'STKBureu-SansMedium';
            src: font-url('assets/woff2/STKBureau-SansMedium.woff2') format('woff2');
            font-weight: normal;
            font-style: normal;
          }

          p, li, td, th {
            font-family: 'STKBureu-SansBook';
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
          }

          h1, h2, h3 {
            font-family: 'STKBureu-SansMedium';
            font-weight: 500;
          }

          h1 {
            font-size: 28px;
            line-height: 40px;
          }

          h2 {
            font-size: 24px;
            line-height: 34px;
          }

          h3 {
            font-size: 18px;
            line-height: 26px;
          }
        `,
    });
  }

  window.tinymce.init({
    selector: '.calculated_question',
    height: 150,
    width: 450,
    menubar: false,
    toolbar: [
      'undo redo | bold italic underline | forecolor | charmap |'
      + 'mathLiveEquationButton destroyEquationButton',
    ],
    plugins: [
      'charmap',
    ],

    color_map: [
      '292929', 'Ink',
      'FFFFFC', 'Notebook',
      '2E69FF', 'Ballpoint',
      '008A66', 'Emeralite',
      'FFD553', 'Ticonderoga',
      'FF0000', 'Pilot',
    ],

    extended_valid_elements: 'math-field[.*]',
    custom_elements: '~math-field',

    setup: customMethods,

    // remove branding logo
    branding: false,

    content_style: '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: white;}'
    + `
        @font-face {
          font-family: 'STKBureu-SansBook';
          src: font-url('assets/woff2/STKBureau-SansBook.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'STKBureu-SansMedium';
          src: font-url('assets/woff2/STKBureau-SansMedium.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        p, li, td, th {
          font-family: 'STKBureu-SansBook';
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
        }

        h1, h2, h3 {
          font-family: 'STKBureu-SansMedium';
          font-weight: 500;
        }

        h1 {
          font-size: 28px;
          line-height: 40px;
        }

        h2 {
          font-size: 24px;
          line-height: 34px;
        }

        h3 {
          font-size: 18px;
          line-height: 26px;
        }
      `,
  });

  window.tinymce.init({
    selector: '.video_chapters',
    height: 250,
    width: 500,
    menubar: false,
    toolbar: [
      'numlist',
    ],
    plugins: [
      'lists',
    ],

    // This ensures the latest content is stored in the tinymce editor textarea
    // before the form is submitted, preventing outdated data issues.
    setup: (editor) => {
      editor.on('change', () => {
        editor.save();
      });
    },

    // remove branding logo
    branding: false,

    // remove element path from status bar (table > tbody > tr > td > p)
    elementpath: false,

    content_style: '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: white;}'
    + `
        @font-face {
          font-family: 'STKBureu-SansBook';
          src: font-url('assets/woff2/STKBureau-SansBook.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'STKBureu-SansMedium';
          src: font-url('assets/woff2/STKBureau-SansMedium.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        p, li, {
          font-family: 'STKBureu-SansBook';
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
        }
      `,
  });

  // Initialize TinyMCE
  initTinyMCE();

  // This event listener is used to initialize TinyMCE when a new nested field is added through cocoon.
  $(document).on('.nested-fields').on('cocoon:after-insert', () => {
    initTinyMCE();
  });
});
